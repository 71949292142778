import React from "react"

import * as styles from "./tippsSection.module.scss"

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function TippsSection() {

  return (
    <div className={styles.tippsSection}>
      <Container>
        <Row className={styles.leftBox}>
          <Col lg={6} className={styles.boxInner}>
            <div className={`${styles.tippsBox} ${styles.yellowBox}`}>
              <h3 className={styles.boxTitle}>Wer hat schon gerne Zahnschmerzen?</h3>
              <p>Mit dem Zähneputzen beginnt die Zahnpflege. Es ist die Basis und bleibt unersetzlich. Zur Vorsoge gegen Karies, Zahnfleischentzündungen, Parodontose und auch Mundgeruch ist es leider allein mit Zähneputzen nicht getan. Aus diesem Grund bieten wir speziell folgende weitere Produkte für ein nachhaltiges Lächeln an: <span className="fw-bold">Zahnseide und Zungenschaber.</span></p>
            </div>
          </Col>
        </Row>
        <Row className={styles.rightBox}>
          <Col lg={6} className={styles.boxInner}>
            <div className={`${styles.tippsBox} ${styles.greenBox}`}>
              <h3 className={`${styles.boxTitle} text-center`}>Zahnpflege Tipps</h3>
              <ul>
                <li>Mindestens zweimal täglich Zähneputzen</li>
                <li>Idealerweise auch nach Mahlzeiten</li>
                <li className="fw-bold">Zahnseide einmal täglich verwenden</li>
                <li className="fw-bold">Zungenschaber morgens verwenden</li>
                <li>Regelmäßige professionelle Zahnreinigung</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
		</div>
  )
}
