import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./productListSection.module.scss"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/free-solid-svg-icons'
import { useAddItemsToCart } from '@jamal./gatsby-theme-shopify-manager';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function ProductListSection(props) {

  const data = useStaticQuery(graphql`
    query {
      recommendationIcon: file(relativePath: { eq: "recommendation_icon.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 25, height: 25, placeholder: NONE)
        }
      },
    }
  `)

  const addItemsToCart = useAddItemsToCart();

  async function handleAddToCart(event) {
    event.preventDefault()
    const variantId = event.currentTarget.dataset.id;
    const quantity = 1;

    const items = [
      {
        variantId: variantId,
        quantity: quantity,
      },
    ];

    try {
      // await addItemsToCart(items);
      alert('Produkt derzeit nicht verfügbar.');
    } catch {
      alert('There was a problem adding that item to your cart.');
    }

    // document.getElementsByClassName("cartContentBG")[0].classList.toggle('is-cart-open');
    // document.body.classList.add("body-lock");
    // document.getElementById("cartOverlay").classList.toggle('is-cart-open');
  }

  const productDetails = {
    '5c8de43d-0bca-512a-ab7e-55ef35f6c8b2' : {
      subtitle: 'Reinigung der Zunge gegen Bakterien & Mundgeruch',
      recommendation: 'Reduziert Mundgeruch',
      keypoints: ['Morgens vor dem Zähneputzen anwenden', 'Schutz vor Karies, Parodontose und Entzündungen', 'Antimikrobielles Material (Edelstahl)', 'Ersetzt mindesten 50 Plastikschaber']
    },
    '4342aea1-d207-59b0-a075-55c871b90bcc' : {
      subtitle: 'Reinigung der Zahnzwischenräume gegen Plaque & zum Schutz des Zahnfleisches',
      recommendation: 'Schützt das Zahnfleisch',
      keypoints: ['Abends vor dem Zähneputzen', 'Entfernt Plaque', 'Aus nachhaltiger Maisstärke in nachfüllbarem Glas', 'Ersetzt mit Plastik verpackte Kunststoffzahnseide 1:1']
    },
    '952c51df-47df-5ff8-ae0c-e412324ad325' : {
      subtitle: 'Handlich & auch für unterwegs geeignet',
      recommendation: 'Kein Plastikbecher mehr notwendig',
      keypoints: ['Schicker, platzsparender Zahnbürstenhalter', '100% Bambus', 'Handlich auch für unterwegs']
    },
    'ed5e00b9-487e-50ee-ba80-bd59a3bce9c6' : {
      subtitle: 'Noch nachhaltiger durch Wechselköpfe',
      recommendation: 'Wiederverwendbarer Zahnbürstengriff',
      keypoints: ['Bambuszahnbürste mit austauschbaren Zahnbürstenköpfen', 'Mittelstarke Borsten', 'Platzsparend auf Reisen']
    },
    '441b7ae2-5e6b-51e4-b27a-3f984ba023c0' : {
      subtitle: 'Hygienisch & nachhaltig auf Reisen',
      recommendation: 'Schützt die Zahnbürste vor äußeren Einflüssen',
      keypoints: ['Wiederverwendbares Zahnbürstenetui', '100% Bambus', 'plastikfreie Zahnpflege auch für unterwegs']
    },
    'f505eb98-fa37-5b6a-b8c9-e21db0d92111' : {
      subtitle: 'Reinigung der Zahnzwischenräume gegen Plaque & zum Schutz des Zahnfleisches',
      recommendation: 'Entfernt Plaque',
      keypoints: ['Abends vor dem Zähneputzen als Ergänzung zu Zahnseide', 'Schützt das Zahnfleisch', 'Aus nachhaltigem Bambus', 'Ersetzt mit Plastik verpackte Kunststoffbürsten 1:1']
    }
  }


  return (
    <div className={styles.productListSection}>
      <Container>
        <h2>Die optimale Zahnpflege & Zubehör</h2>
        <h3 className="d-none d-lg-block">Das Zubehörset enthält alle nachstehenden Produkte mit 20% Rabatt.<br />Hier siehst du die Auswahl der Einzelprodukte.</h3>
        <h3 className="d-block d-lg-none">Das Set enthält alle folgenden Produkte mit 20% Rabatt.</h3>
        {props.products.nodes.map(product => (
          <Row className={styles.productRow}>
            <Col lg={6}>
              <GatsbyImage image={product.images[0].localFile.childImageSharp.gatsbyImageData} className={`d-none d-md-block ${styles.productImg}`} alt={product.title} />
            </Col>
            <Col lg={6} className={styles.rightBox}>
              <h2>{product.title}</h2>
              <p className={styles.subtitle}>{productDetails[product.id].subtitle}</p>
              <GatsbyImage image={product.images[0].localFile.childImageSharp.gatsbyImageData} className={`d-block d-md-none ${styles.productImg}`} alt={product.title} />
              <p className={styles.recommendation}><GatsbyImage loading="eager" className={styles.recommendationIcon} image={data.recommendationIcon.childImageSharp.gatsbyImageData} alt="Recommendation" /><span>{productDetails[product.id].recommendation}</span></p>
              <ul>
                {productDetails[product.id].keypoints.map((keypoint, index) => (
                  <>
                  {index < productDetails[product.id].keypoints.length-2 ? (
                    <li><FontAwesomeIcon icon={faCheck} className="mr-2 text-black small" /> {keypoint}</li>
                  ) : (
                    <li className="fw-bold"><FontAwesomeIcon icon={faCheck} className="mr-2 text-black small" /> {keypoint}</li>
                  )}
                  </>
                ))}
              </ul>
              <a href="#atc" className={styles.atcBtn} data-id={product.variants[0].shopifyId} onClick={handleAddToCart}>Für {product.priceRangeV2.maxVariantPrice.amount.replace('.', ',')}€ kaufen</a>
            </Col>
          </Row>
        ))}
      </Container>
		</div>
  )
}
