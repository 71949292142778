import React, { useState, useEffect } from "react"
import Layout from "../../components/layout/layout"

import { useStaticQuery, graphql } from "gatsby"

import SingleProductHeader from "../../components/product/singleProductHeader"
import SingleProductSlogan from "../../components/product/singleProductSlogan"
import SingleProductInitialQuestion from "../../components/product/singleProductInitialQuestion"
import TippsSection from "../../components/product/tippsSection"
import ProductListSection from "../../components/product/productListSection"
import AllReviews from "../../components/reviews/allReviews"
import FaqSection from "../../components/product/faqSection"
import CustomersBought from "../../components/product/customersBoughtSection"
import CookieConsent from "react-cookie-consent";

import { sortImages } from '../../utils/helper'

import useIsClient from '../../useIsClient';

import Seo from "../../components/layout/seo";

export default function Zubehoerset() {

  const { isClient, key } = useIsClient();

  const data = useStaticQuery(graphql`
    query {
      allShopifyProduct(
        sort: {fields: [title]}
        filter: {id: {eq: "46e3acb2-5819-530c-9958-2a4e07ba522e"}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          images {
            localFile {
              name
              childImageSharp {
                gatsbyImageData(height: 90, width: 90, placeholder: NONE, layout: FIXED)
              }
            }
          }
        }
      },
      productThumbnails: allShopifyProduct(sort: {fields: [title]}, filter: {id: {eq: "46e3acb2-5819-530c-9958-2a4e07ba522e"}}) {
        edges {
          node {
            images {
              originalSrc
              id
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 105, width: 84, placeholder: NONE)
                }
              }
            }
          }
        }
      },
      singleItems: allShopifyProduct(
        sort: {fields: [title], order: DESC}
        filter: {id: {in: ["5c8de43d-0bca-512a-ab7e-55ef35f6c8b2", "952c51df-47df-5ff8-ae0c-e412324ad325", "4342aea1-d207-59b0-a075-55c871b90bcc", "441b7ae2-5e6b-51e4-b27a-3f984ba023c0", "ed5e00b9-487e-50ee-ba80-bd59a3bce9c6"]}}
      ) {
        edges {
          node {
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
          priceRangeV2 {
            maxVariantPrice {
              amount
            }
            minVariantPrice {
              amount
            }
          }
          title
          images {
            originalSrc
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: NONE)
              }
            }
          }
          id
        }
      },
      customersBought: allShopifyProduct(
        sort: {fields: [title], order: DESC}
        filter: {id: {in: ["72c83be1-b5c8-5b3d-aa9a-d4c69816c66a", "8d651639-2db7-5e3a-a3aa-1866bb0d9f53"]}}
      ) {
        edges {
          node {
            title
            images {
              originalSrc
              localFile {
                name
                childImageSharp {
                  gatsbyImageData(layout: FIXED, height: 650, width: 625, placeholder: NONE)
                }
              }
            }
            options {
              name
              values
            }
            shopifyId
            description
            priceRangeV2 {
              maxVariantPrice {
                amount
              }
              minVariantPrice {
                amount
              }
            }
            id
            variants {
              id
            }
            handle
          }
        }
        nodes {
          variants {
            title
            shopifyId
            selectedOptions {
              name
              value
            }
            metafields {
              key
              value
            }
            price
            compareAtPrice
          }
        }
      },
      monatspaketReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["6899851854007"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      starterkitReview: allDropsReview(
        sort: {fields: createdAt, order: DESC}
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351701078250"]}}, rating: {gte: 1}}
      ) {
        totalCount
        sum(field: rating)
      },
      allDropsReview(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}, rating: {gte: 1}}
        sort: {fields: createdAt, order: DESC}
      ) {
        edges {
          node {
            body
            createdAt
            files
            id
            name
            rating
            title
            productIds {
              ids
            }
          }
        }
        totalCount
        sum(field: rating)
      },
      allDropsQuestion(
        filter: {curated: {eq: true}, productIds: {ids: {in: ["7351700848874"]}}}
      ) {
        edges {
          node {
            body
            curated
            name
            title
            createdAt
            productQuestionAnswers {
              body
              createdAt
            }
          }
        }
      }
    }
  `)

  // reorder images
  data.allShopifyProduct.edges[0].node.images = sortImages('localFile.name', data.allShopifyProduct.edges[0].node.images);
  data.allShopifyProduct.nodes[0].images = sortImages('localFile.name', data.allShopifyProduct.nodes[0].images);
  data.productThumbnails.edges[0].node.images = sortImages('localFile.name', data.productThumbnails.edges[0].node.images);
  data.customersBought.edges[0].node.images = sortImages('localFile.name', data.customersBought.edges[0].node.images);
  data.customersBought.edges[0].node.reviewsCount = data.starterkitReview.totalCount;
  data.customersBought.edges[0].node.reviewsRating = (data.starterkitReview.sum/data.starterkitReview.totalCount).toFixed(1);
  data.customersBought.edges[1].node.images = sortImages('localFile.name', data.customersBought.edges[1].node.images);
  data.customersBought.edges[1].node.reviewsCount = data.monatspaketReview.totalCount;
  data.customersBought.edges[1].node.reviewsRating = (data.monatspaketReview.sum/data.monatspaketReview.totalCount).toFixed(1);

  const [variantArrayData, setVariantArrayData] = useState(null);

  const activeVariantKeyString = data.allShopifyProduct.edges[0].node.options[0].values[0].replace(' ', '_');
  const activeVariantId = data.allShopifyProduct.nodes[0].variants[0].shopifyId

  useEffect(() => {
    var variantArray = {}
    var regex = /[+-]?\d+(\.\d+)?/g;
    data.allShopifyProduct.nodes[0].variants.forEach((variant, variantKey) => {
      variantArray[variant.selectedOptions[0].value.replace(' ', '_')] = {
        productTitle: data.allShopifyProduct.edges[0].node.title,
        title: variant.title,
        price: parseFloat(variant.price),
        compareAtPrice: variant.compareAtPrice,
        shopifyId: variant.shopifyId,
        productImg: data.allShopifyProduct.nodes[0].images[0].localFile,
        tryImg: data.tryImg,
        basePrice: variant.metafields.length > 0 ? variant.metafields[0].value.replace(',','.').match(regex).map(function(v) { return parseFloat(v); })[0] : 0
      }
    });
    setVariantArrayData(variantArray);
  }, [data]);

  const productData = {
    product: data.allShopifyProduct,
    productThumbnails: data.productThumbnails,
    variants: variantArrayData,
    activeVariantKeyString: activeVariantKeyString,
    activeVariantId: activeVariantId,
    title: 'Zubehörbox (5 Produkte)',
    titleAbove: 'Tägliche Mundpflege',
    name: 'Zubehörbox',
    description: <><p><span className='font-weight-bold'>Zubehörbox & kostenlose Lieferung</span> — Alles was du für eine vollständige Mundpflege brauchst in einem Set und komplett nachhaltig.</p><p>Plastikfreies und nachhaltiges Zahnpflegeset bestehend aus:</p></>,
    keypoints: ['Reinigung zum Schutz des Zahnfleisches', 'Hilft gegen Plaque', 'Beugt Bakterien & Mundgeruch vor', 'Handlich & auch für unterwegs geeignet', 'Hygienisch & nachhaltig auf Reisen', 'Wiederverwendbarer Zahnbürstengriff'],
    keypointsShort: ['Zahnseide', 'Zungenschaber', 'Zahnbürstenhalter', 'Reiseetui', 'Wechselzahnbürste'],
    showSelection: false,
    showDetails: true,
    sliderTitle: 'Ideale Zusatzprodukte für beste Mundpflege'
  }

  let productIds = {
  	"ids": [
  		"7351700848874"
  	]
  }

  if ( !isClient ) return null;

  return (
    <Layout activeVariant={variantArrayData[activeVariantKeyString]} key={key}>
      <Seo title="Drops Starterkit | Natürliche Minze | Toothpaste Drops" path="/products/starterkit" description="Über 5000 zufriedene Kunden - Natürlich - Plastikfrei - Ohne Tierversuche - Vegan - Zahnpasta Drops. Hilf uns den Plastikmüll in unserer Umwelt & den Meeren zu beenden. #weltretter" />
      <SingleProductHeader data={productData} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} reviewCount={data.allDropsReview.edges.length} />
      <SingleProductSlogan slogan={<>“Erst wenn der Körper<br />streikt, merken wir wie<br />wichtig unsere Gesundheit<br />ist.”</>} sloganSubtext="Zitat: Jeder Mensch, wenn er krank ist." />
      <SingleProductInitialQuestion question={<>Warum reicht Zähneputzen<br />alleine nicht aus?</>} />
      <TippsSection />
      <ProductListSection products={data.singleItems} />
      <AllReviews reviews={data.allDropsReview} questions={data.allDropsQuestion} reviewRating={(data.allDropsReview.sum/data.allDropsReview.totalCount).toFixed(1)} productIds={productIds} />
      <FaqSection />
      <CustomersBought data={data.customersBought} />
      <CookieConsent style={{ background: "#000000", color: "#ffffff", fontSize: "14px", alignItems: "center" }} contentClasses="m-2" buttonClasses="m-2" buttonStyle={{ color: "#ffffff", background: "#0275d8", fontSize: "13px" }} buttonText="OK">Zur Bereitstellung von Diensten verwenden wir Cookies. Durch die Nutzung dieser Website stimmen Sie zu.</CookieConsent>
    </Layout>
  )
}
